import { ApiFactory } from './Api';

const api = new ApiFactory();

async function login(request) {
    const response = await api.post(`/login`, request);
    return response.data;
}

async function logout() {
    const response = await api.post(`/logout`);
    return response.data;
}

async function forgetPassword(request) {
    const response = await api.post(`/forget_password`, request);
    return response;
}

async function resetPassword(request) {
    const response = await api.post(`/reset_password`, request);
    return response;
}

async function checkResetToken(token) {
    const response = await api.post(`/reset_tokens/verify`, { token });
    return response;
}

async function changePassword(request) {
    const response = await api.post(`/change_password`, request);
    return response;
}

async function setPassword(request) {
    const response = await api.post(`/set_password`, request);
    return response;
}

export { login, logout, forgetPassword, resetPassword, checkResetToken, changePassword, setPassword };
