import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../components/Loadable';
import { ROUTES, paths, publicRoutes } from '../constants';
import ErrorPage from '../components/ErrorPage';
import NotFound from '../components/NotFound';
import { checkContract } from '../api/ContractApi';
import { useQuery } from 'react-query';
import NotAccessPage from '../components/NotAccessPage';
import { checkResetToken } from '../api/AuthApi';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '../hooks/ToastProvider';
import { fetchCustomerInfo } from '../api/CustomerApi';
import { SET_PROFILE } from '../redux/_user.redux';

// dashboard routing
const LoginDefault = Loadable(lazy(() => import('../views/login')));
const ContractDefault = Loadable(lazy(() => import('../views/contract')));
const CarDefault = Loadable(lazy(() => import('../views/car')));
const MemberDefault = Loadable(lazy(() => import('../views/member')));
const ForgetPasswordDefault = Loadable(lazy(() => import('../views/forgetPassword')));
const ResetPasswordDefault = Loadable(lazy(() => import('../views/resetPassword')));
const ChangePasswordDefault = Loadable(lazy(() => import('../views/changePassword')));
const SetPasswordDefault = Loadable(lazy(() => import('../views/setPassword')));
const NotificationDefault = Loadable(lazy(() => import('../views/notification')));
const ProfileDefault = Loadable(lazy(() => import('../views/profile')));
const ContractDetailDefault = Loadable(lazy(() => import('../views/contract/[id]')));
// const EditProfileDefault = Loadable(lazy(() => import('../views/editProfile')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.user.profile);
    const currentPath = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const contractId = searchParams.get('contractId');
    const token = searchParams.get('token');
    const resetToken = searchParams.get('reset_token');
    const accountId = searchParams.get('sfid');
    const hasViewAccess = currentPath === ROUTES.CAR;
    const {
        data: userContractRole,
        isLoading: isLoadingCheckContract,
        error: checkContractError
    } = useQuery(['checkContract', contractId], () => checkContract(contractId, hasViewAccess), {
        enabled: (!!contractId && paths.includes(currentPath) && currentPath === ROUTES.CAR) || currentPath === ROUTES.MEMBER
    });
    const { error: checkResetTokenError } = useQuery(['checkResetToken', resetToken], () => checkResetToken(resetToken), {
        enabled: !!resetToken && currentPath === ROUTES.RESET_PASSWORD
    });

    if (token) {
        localStorage.setItem('token', token);
    }
    const isUserLoggedIn = Boolean(localStorage.getItem('token'));
    const isChangePasswordNotLoggedIn = currentPath === ROUTES.CHANGE_PASSWORD && !!accountId;
    const isNotPublicPage = !publicRoutes.includes(currentPath) && isUserLoggedIn && !checkContractError && !isChangePasswordNotLoggedIn;
    const isPageNotAccess = !publicRoutes.includes(currentPath) && !isUserLoggedIn;

    const { showToast } = useToast();
    useQuery(['customers'], () => fetchCustomerInfo(), {
        enabled: !profile && currentPath !== ROUTES.LOGIN,
        onSuccess: (data) => {
            dispatch({ type: SET_PROFILE, profile: data || null });
        },
        onError: () => {
            showToast();
        }
    });

    const renderMainLayout = (component) => <MainLayout isNotPublicPage={isNotPublicPage}>{component}</MainLayout>;

    const renderRoutes = () => (
        <Switch location={location} key={currentPath}>
            <Route path={ROUTES.LOGIN} component={LoginDefault} />
            <Route path={ROUTES.CONTRACT} component={ContractDefault} />
            <Route path={ROUTES.CAR} component={() => <CarDefault userContractRole={userContractRole} />} />
            <Route path={ROUTES.MEMBER} component={MemberDefault} />
            <Route path={ROUTES.FORGET_PASSWORD} component={ForgetPasswordDefault} />
            <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordDefault} />
            <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePasswordDefault} />
            <Route path={ROUTES.SET_PASSWORD} component={SetPasswordDefault} />
            <Route path={ROUTES.NOTIFICATION} component={NotificationDefault} />
            <Route path={ROUTES.PROFILE} component={ProfileDefault} />
            <Route path={ROUTES.CONTRACT_DETAIL} component={ContractDetailDefault} />
            {/* <Route path={ROUTES.EDIT_PROFILE} component={EditProfileDefault} /> */}
        </Switch>
    );
    if (!paths.includes(currentPath)) {
        const matchContracts = currentPath.match(/^\/contracts\/([a-zA-Z0-9]+)$/);
        if (matchContracts) {
            return renderMainLayout(<Route path={ROUTES.CONTRACT_DETAIL} component={ContractDetailDefault} />);
        }
        return renderMainLayout(<Route path={currentPath} component={NotFound} />);
    }

    if (checkContractError) {
        return renderMainLayout(<Route path={currentPath} component={ErrorPage} />);
    }

    if (isPageNotAccess || checkResetTokenError || (!resetToken && currentPath === ROUTES.RESET_PASSWORD)) {
        return renderMainLayout(<Route path={currentPath} component={NotAccessPage} />);
    }

    return <>{!isLoadingCheckContract ? <Route path={paths}>{renderMainLayout(renderRoutes())}</Route> : <MainLayout />}</>;
};

export default MainRoutes;
